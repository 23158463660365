<template>
  <v-app id="inspire">
    <v-app-bar app clipped-left flat color="primary" class="header">
      <v-container class="container_principal_pedido">
        <v-row>
          <v-col cols="9" class="logoConfigurarPedido" align="left">
            <a href="/">
              <img src="@/assets/logo_blanco.svg" class="logoBlanco" />
            </a>
          </v-col>
          <v-col
            cols="3"
            class="botonCambiarIdiomaWebCOnfigurarPedido"
            align="right"
          >
            <v-btn
              class="botonCambiarIdiomaPagina"
              v-if="this.$i18n.locale === 'gl'"
              @click="castellano()"
              ><span style="font-weight: lighter">ES</span> |
              <strong>GL</strong></v-btn
            >
            <v-btn
              class="botonCambiarIdiomaPagina"
              v-if="this.$i18n.locale === 'es'"
              @click="gallego()"
              ><strong>ES</strong> |
              <span style="font-weight: lighter">GL</span></v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>
    <v-main>
      <v-container class="container_principal_pedido">
        <v-row>
          <v-col cols sm="12" style="text-align: justify">
            <v-row>
              <v-col cols>
                <h1 style="text-align: center">Aviso legal</h1>
                <p style="padding-top: 25px">
                  <b>0. {{ $t("avisoLegal.titulo1") }}</b>
                </p>
                <p>
                  {{ $t("avisoLegal.parrafo1") }}
                </p>
                <p>
                  <b>1. {{ $t("avisoLegal.titulo2") }}</b>
                </p>
                <p>{{ $t("avisoLegal.parrafo2") }}</p>
                <p>
                  {{ $t("avisoLegal.parrafo3.texto") }}
                  <b>{{ $t("avisoLegal.parrafo3.textoNegrita") }}</b>
                </p>
                <p>
                  {{ $t("avisoLegal.parrafo4.texto") }}
                  <b>{{ $t("avisoLegal.parrafo4.textoNegrita") }}</b>
                </p>
                <p>
                  {{ $t("avisoLegal.parrafo5.texto") }}
                  <b>{{ $t("avisoLegal.parrafo5.textoNegrita") }}</b>
                </p>
                <p>{{ $t("avisoLegal.parrafo6") }}</p>
                <p>{{ $t("avisoLegal.parrafo7") }}</p>
                <p>
                  {{ $t("avisoLegal.parrafo8.texto") }}
                  <b>
                    <a href="tel:+34982650065">{{
                      $t("avisoLegal.parrafo8.telefono")
                    }}</a></b
                  >
                </p>
                <p>
                  {{ $t("avisoLegal.parrafo9.texto") }}
                  <b>{{ $t("avisoLegal.parrafo9.correo") }}</b>
                </p>
                <p>{{ $t("avisoLegal.parrafo10") }}</p>
                <p>
                  <b>2. {{ $t("avisoLegal.titulo3") }}</b>
                </p>
                <p>{{ $t("avisoLegal.parrafo11") }}</p>
                <p>{{ $t("avisoLegal.parrafo12") }}</p>
                <p>{{ $t("avisoLegal.parrafo13") }}</p>
                <p>{{ $t("avisoLegal.parrafo14") }}</p>
                <p>{{ $t("avisoLegal.parrafo15") }}</p>
                <p>{{ $t("avisoLegal.parrafo16") }}</p>
                <p>{{ $t("avisoLegal.parrafo17") }}</p>
                <p>{{ $t("avisoLegal.parrafo18") }}</p>
                <p>{{ $t("avisoLegal.parrafo19") }}</p>
                <p>{{ $t("avisoLegal.parrafo20") }}</p>
                <p>{{ $t("avisoLegal.parrafo21") }}</p>
                <p>{{ $t("avisoLegal.parrafo22") }}</p>
                <p>{{ $t("avisoLegal.parrafo23") }}</p>
                <p>
                  <b>3. {{ $t("avisoLegal.titulo4") }}</b>
                </p>
                <p>{{ $t("avisoLegal.parrafo24") }}</p>
                <p>{{ $t("avisoLegal.parrafo25") }}</p>
                <p>{{ $t("avisoLegal.parrafo26") }}</p>
                <p>{{ $t("avisoLegal.parrafo27") }}</p>
                <p>{{ $t("avisoLegal.parrafo28") }}</p>
                <p>{{ $t("avisoLegal.parrafo29") }}</p>
                <p>
                  <b>4. {{ $t("avisoLegal.titulo5") }}</b>
                </p>
                <p>{{ $t("avisoLegal.parrafo30") }}</p>
                <p>{{ $t("avisoLegal.parrafo31") }}</p>
                <p>{{ $t("avisoLegal.parrafo32") }}</p>
                <p>{{ $t("avisoLegal.parrafo33") }}</p>
                <p>{{ $t("avisoLegal.parrafo34") }}</p>
                <p>
                  <b>5. {{ $t("avisoLegal.titulo6") }}</b>
                </p>
                <p>{{ $t("avisoLegal.parrafo35") }}</p>
                <p>{{ $t("avisoLegal.parrafo36") }}</p>
                <p>
                  <b>6. {{ $t("avisoLegal.titulo7") }}</b>
                </p>
                <p>{{ $t("avisoLegal.parrafo37") }}</p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <!-- Footer -->
    <FooterView />
    <!-- Footer -->
  </v-app>
</template>

<script>
export default {
    name: 'App',
    components: {
        FooterView: () => import('./footer.vue')
    },
    data () {
        return {
            e6: 1,
            datosPortabilidadesValidados: true,
            datosClienteValidados: false
        }
    },
    methods: {
        castellano () {
            this.$i18n.locale = 'es'
        },
        gallego () {
            this.$i18n.locale = 'gl'
        },
        volverPortada () {
            this.$store.dispatch('setcarritoPedidosLineasMovil', '')
            this.$store.dispatch('setLineasMovil', [])

            this.$router.push('/')
        }
    },

    mounted () {
        window.scrollTo(0, 0)
    }
}
</script>
<style>
</style>
